body {
    margin: 0;
    padding: 0;
}

.mdv-ctrl {
    font-family: Avenir-Roman, "Segoe UI", sans-serif;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 1px 4px rgba(0,0,0,0.3);
    padding: 10px;
    position: absolute;
    z-index: 1;
}

.mdv-ctrl-legend {
    left: 10px;
    bottom: 10px;
    text-align: center;
}

.mdv-ctrl-layer {
    padding: 0;
    background: none;
    border-radius: 5px;
}

.mdv-ctrl-legend h6 {
    margin: 0 0 10px;
}

.mdv-ctrl-legend td span {
    border-radius: 50%;
    display: inline-block;
    height: 10px;
    margin-right: 5px;
    width: 10px;
}

.mapMarkerStyle {
    background-color: blue;
    width: 1.5rem;
    height: 1.5rem;
    display: block;
    left: -0.5rem;
    top: -0.5rem;
    position: relative;
    border-radius: 1.5rem 1.5rem 0;
    transform: rotate(45deg);
    border: 1px solid #FFFFFF;
}

.mapMarkerStyle::after {
    position: absolute;
    content: '';
    width: 0.4rem;
    height: 0.4rem;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    margin-left: -4px;
    margin-top: -4px;
    background-color: #fff;
}


