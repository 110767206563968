body {
    margin: 0;
    padding: 0;
}


.mdv-ctrl {
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 1px 4px rgba(0,0,0,0.3);
    padding: 10px;
    position: absolute;
    z-index: 1;
}

.mdv-ctrl-legend {
    left: 10px;
    bottom: 10px;
    text-align: center;
}

.mdv-ctrl-layer {
    padding: 0;
    background: none;
    border-radius: 5px;
}

.mdv-ctrl-legend h6 {
    margin: 0 0 10px;
}

.mdv-ctrl-legend td span {
    border-radius: 50%;
    display: inline-block;
    height: 10px;
    margin-right: 5px;
    width: 10px;
}

.marker-icon {
    color: green;
    filter: drop-shadow( 0 0 5px #FFFFFF );
}

