/* Put global css here */

:root {
    --text-gray-black: #404040;

    --max-green-yellow: #d7f75b;
    --lemon-curry: #d19c1d;
    --colorbogus: #d34e24;
    --dark-spring-green: #297045;
    --lapiz-lazuli: #2274a5;
}

@font-face {
    font-family: 'Avenir-Roman';
    src: url('fonts/AvenirLTStd-Roman.otf') format('opentype');
    font-weight: normal;
    font-style: all;
}

body {
    font-family: Avenir-Roman, "Segoe UI", sans-serif;
    color: var(--text-gray-black);
}

.container {
    margin-top: 1em
}

a, a:hover, a:visited, a:link, a:active {
    text-decoration: none;
}

table, th {
    vertical-align: top !important;
}

.table {
    border-radius: 2px;
    border: 1px solid #dee2e6;
}

.pagination {
    justify-content: center;
}

