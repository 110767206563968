
.toast-ases-success {
    background-color: #8acc48;
}

.toast-ases-error {
    background-color: #e74c3c;
}

.toast-ases-warning {
    background-color: #f1c40f;
}

