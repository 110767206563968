#menu-content {
    background: #FFF;
    height: 100%;
    width: 17em;
}

#menu-content img#logo {
    width: 100%;
    height: auto;
}

.menu-sidebar {
    box-shadow: rgba(0, 0, 0, 0.15) 3px 0 5px !important;
}

.nav-row {
    margin-left: 0;
    margin-right: 0;
}

.nav-col {
    padding-right: 0;
    padding-left: 0;
}

.nav-link-children .nav-link {
    padding-left: 1.5em
}

.nav-link-parent-top {
    /*margin-top: 1em;*/
    /*border-top: 1px solid #c8cbce;*/
}

.nav-link-parent, .nav-link {
    cursor: pointer;
    color: black;
    background: none;
    border: none;
    /*border-bottom: 1px solid #c8cbce;*/
    /*background-color: #f3f6f9;*/
}

.nav-link-parent:hover, .nav-link:hover {
    color: darkblue;
}


.nav-link-parent::after {
    content: "›";
    position: absolute;
    right: 0.5em
}

.nav-link-parent-active::after {
    content: "‹";
    position: absolute;
    right: 0.5em
}

.nav-link.active {
    border-radius: 3px;
    background-color: #e6e9ec;
}
